module.exports.init = function () {

	require('./typeahead.jquery');
	Bloodhound = require('./bloodhound');
	Levenshtein = require('./levenshtein');
	jQuery(document).ready(function ($) {

		// ----- Find a member subscriptions
		/**
		 * Setup form typeaheads
		 *
		 * @dependecies: typeahead, fast-levenshtein
		 */
		$.subscribe('find_a_member/setup_typeaheads', function () {
		});

		/**
		 * Setup slider
		 * @dependencies: rangeslider
		 */
		$.subscribe('find_a_member/setup_slider', function () {
			var radiusValue$ = $('#radius-value');
			FindAMember._elements.slider.rangeslider({

				onSlideEnd: function (position, value) {
					FindAMember.search();
				},
				onSlide: function (position, value) {
					radiusValue$.text(value + ' km');
				}
			});
		});

		/**
		 * Update search result title
		 * @param json Data from search results
		 * @param object Form values
		 */
		$.subscribe('find_a_member/update_title', function (event$, searchResults, formValues) {
			var total = searchResults.total,
				location = searchResults.location,
				search = formValues.search,
				radius = searchResults.radius,
				memberText = (total == 1) ? 'result' : 'results',
				searchTerms = '';

			if (typeof search !== 'undefined' && search !== null) {
				searchTerms += 'for name "' + search + '" ';
			}
			if (typeof location.title !== 'undefined' && location.title !== null) {
				searchTerms += 'at "' + location.title + '"';
			}
			if (typeof radius !== 'undefined' && location.title !== '' && location.title !== null) {
				searchTerms += ' within ' + radius + ' km';
			}

			var title = total + ' ' + memberText + ' found ' + searchTerms;
			FindAMember.get_element('title').html(title);
		});

		/**
		 * Setup page specific events
		 */
		$.subscribe('find_a_member/handle_changes', function () {

			let name$ = FindAMember.get_element('name');
			let locationSearch$ = FindAMember.get_element('locationSearch');

			//On form submission run the search
			FindAMember.get_element('form').on('submit', function (e) {
				e.preventDefault();
				$('#latitude').val('');
				$('#longitude').val('');

				let autocompleteEl = document.getElementById(FindAMember._elements.locationSearch.attr('id'));

				//Geocode the location if the user has not clicked on the result
				if (autocompleteEl && locationSearch$.val() !== '' && $(".pac-item-selected").length === 0) {
					var geocoder = new google.maps.Geocoder();
					geocoder.geocode({'address': locationSearch$.val()}, function (results, status) {
						if (status === google.maps.GeocoderStatus.OK && results[0]) {

							var lat = results[0].geometry.location.lat();
							var lng = results[0].geometry.location.lng();
							var formattedAddress = results[0].formatted_address;

							$('#geometry_latitude').val(lat);
							$('#geometry_longitude').val(lng);
							$('#administrative_area_level_1').val(formattedAddress);
							FindAMember.get_element('locationSearch').val(formattedAddress);

							FindAMember.search();
						}
					});
				} else {
					FindAMember.search();
				}
			});

			$('.js-action-clear').on('click', function (e) {
				e.preventDefault();
				FindAMember.get_element('form').trigger('reset');
			});

			$('#use_my_location').on('click', function (e) {
				e.preventDefault();
				locationSearch$.val('');
				FindAMember.use_my_current_location(true);
			});

		});

		function getScrollToYPosition() {
			return $('.results').offset().top;
		}

		// ----- Initialise Find a member
		FindAMember.init({
			templates: {
				member: '#member-list-item',
				pagination: '#member-pagination',
				pinHtml: '#member-google-map-pin-html',
			},
			elements: {
				form: 'form.form-find-a-member',
				listResults: '.list-results',
				locationSearch: '#search-location',
				modal: '#modal__find-a-member',
				pagination: '.search-results-pagination',
				title: '.search-results-title',
				slider: '#radius',
				buttonCurrentLocation: '#use_my_location',
			},
			settings: {
				scroll_to_results: {
					enabled: true,
					scroll_to_y_position: getScrollToYPosition(),
					scroll_time_ms: 600
				},
				pagination: {item_count: 10},
				member_title: 'results'
			}
		});

		// ----- Initialise GoogleMaps
		var mapFindMember$ = $('#map-find-a-member');

		var googleMapsOptions = {
			mapHolder: mapFindMember$,
			showCurrentPosition: true,
			scrollDownControls: true,
			showHideControls: true,
			pinColourActive: '#4daaab',
			pinColourInactive: '#4daaab'
		};

		GoogleMaps.init(googleMapsOptions);

		//Wait for Google maps to finish loading, before performing a search
		$.subscribe('google-maps.initialised', function () {
			// If get params exist (browser back from detail page): trigger the search
			if ($('#search-location').val() !== '') {
				FindAMember.search();
			} else if (mapFindMember$.data('search-user-location')) {
				var shouldShowError = function (error) {
					//unexpected type of error data...
					if (!error || !error.code || !error.PERMISSION_DENIED) {
						return true;
					}

					if (error.code === error.PERMISSION_DENIED) {
						console.warn('User denied geolocation, will not auto-search based on location.');
						return false;
					}

					return true;
				}
				FindAMember.use_my_current_location(shouldShowError).fail(function () {
					FindAMember.search_all();
				});
			} else {
				FindAMember.search_all();
			}
		});

	});
}
